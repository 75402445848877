import { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Helpers
import { getAcademyPlaylist, getUpcomingWebinars } from './actions';

// Components
import Header from './Header';
import Summary from './Summary';
import Academy from './Academy';
import TopNav from '@/components/Shared/TopNav';
import BusinessVerificationBanner from '@/components/Shared/BusinessVerification/VerificationBanner';
import StripeExpressMigrationBanner from '@/components/Shared/StripeExpressMigration/MigrationBanner';

// Styles
import './style.css';

const Home = function Home() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { studio: loginStudio } = useSelector((state) => state.login);
  const { studio: usersStudio } = useSelector((state) => state.users);

  const studio = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;
  const { id: studioId } = studio;

  console.debug('home studio ', studio);

  useEffect(() => {
    dispatch(getAcademyPlaylist());
    dispatch(getUpcomingWebinars());
  }, []);

  return (
    <>
      <TopNav />
      <section id="home" className="box">
        <Header path={location.pathname} studioId={studioId} />

        {/* Business Verification Banner */}
        <BusinessVerificationBanner />

        {/* Stripe Express Migration Banner */}
        <StripeExpressMigrationBanner />

        <Switch>
          <Redirect exact from="/home" to="/home/summary" />
          <Route path="/home/summary" component={Summary} />
          <Route path="/home/academy" component={Academy} />
        </Switch>
      </section>
    </>
  );
};

export default Home;
